import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Estilos
import {
    HeaderDescription,
    TableContents,
    PaginatorContent
} from './styles';

function NoSuggestionsMessage() {
    return (
        <tr>
            <td colSpan="4" className='noSuggestion'>Não há nada para mostrar!</td>
        </tr>
    );
}

export function Header({ Description }) {
    return (
        <HeaderDescription>
            <h3>{Description}</h3>
        </HeaderDescription>
    );
}

export function Table({ suggestions, onApprove, onReprove }) {
    const [show, setShow] = useState(false);
    const [selectedContent, setSelectedContent] = useState('');
    const [selectedId, setSelectedId] = useState()

    const handleClose = () => setShow(false);

    const handleShow = (content, id) => {
        setSelectedContent(content);
        setSelectedId(id);
        setShow(true);
    };

    return (
        <TableContents>
            <thead>
                <tr>
                    <th className="content-column">Conteúdo</th>
                    <th className="date-column">Data de Publicação</th>
                    <th className="user-column">Usuário</th>
                </tr>
            </thead>
            <tbody>
                {suggestions && suggestions.length > 0 ? (
                    suggestions.map((suggestion) => (
                        <tr key={suggestion.id}>
                            <td className="content-column">
                                <div className="content-wrapper">
                                    {suggestion.content.length > 100
                                        ? <>
                                            {suggestion.content.substring(0, 30)}...
                                            <Button variant="link" className="show-more-button" onClick={() => handleShow(suggestion.content, suggestion.id)}>Ver mais</Button>
                                        </>
                                        : suggestion.content
                                    }
                                </div>
                            </td>
                            <td className='date-column'>{suggestion.published_at}</td>
                            <td className='user-column'>{suggestion.user}</td>
                            <td className="buttons actions-column">
                                <button className="btn btn-success me-2" onClick={() => onApprove(suggestion.id)}>
                                    <i className="bi bi-check-lg" /> Aprovar
                                </button>
                                <button className="btn btn-danger" onClick={() => onReprove(suggestion.id)}>
                                    <i className="bi bi-x-lg" /> Reprovar
                                </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <NoSuggestionsMessage />
                )}
            </tbody>
            {/* modal para exibir conteúdos extensos */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Conteúdo Completo</Modal.Title>
                </Modal.Header>
                <Modal.Body>{selectedContent}</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success me-2" onClick={() => onApprove(selectedId)}>
                        <i className="bi bi-check-lg" /> Aprovar
                    </button>
                    <button className="btn btn-danger" onClick={() => onReprove(selectedId)}>
                        <i className="bi bi-x-lg" /> Reprovar
                    </button>

                </Modal.Footer>
            </Modal>
        </TableContents>
    );
}

export function Paginator({ pages }) {
    if (pages > 1) {
        return (
            <PaginatorContent>
                {Array.from(Array(pages), (item, index) => (
                    <button key={index}>{index + 1}</button>
                ))}
            </PaginatorContent>
        );
    } else {
        return null;
    }
}
