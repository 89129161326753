import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FeedService from "../services/FeedService";

const initialState = {
    errorPosts: null,
    successPosts: false,
    loadingPosts: false,
    posts: []
};

export const getPosts = createAsyncThunk(
    "auth/getPosts",
    async (dataUser, thunkAPI) => {
        try {
            const response = await FeedService.getPosts(dataUser);

            return response.data;
        } catch(error) {
            return thunkAPI.rejectWithValue(error.error);
        }
    }
);

export const getPostsIntitution = createAsyncThunk(
  "auth/getPostsIntitution",
  async (nameInstitution, thunkAPI) => {
      try {
          const response = await FeedService.getPostsIntitution(nameInstitution);

          return response.data;
      } catch(error) {

        return thunkAPI.rejectWithValue(error.error);
      }
  }
);

const postsSlice = createSlice({
    name: "posts",
    initialState,
    reducers: {
        reset: (state) => {
            state.errorPosts = null;
            state.successPosts = false;
            state.loadingPosts = false;
            state.posts = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPosts.pending, (state) => {
                state.loadingPosts = true;
            })
            .addCase(getPosts.fulfilled, (state, action) => {
                state.loadingPosts = false;
                state.successPosts = true;
                state.errorPosts = null;
                state.posts = action.payload;
            })
            .addCase(getPosts.rejected, (state, action) => {
                state.loadingPosts = false;
                state.errorPosts = action.payload;
            })
            .addCase(getPostsIntitution.pending, (state) => {
              state.loadingPosts = true;
            })
            .addCase(getPostsIntitution.fulfilled, (state, action) => {
                state.loadingPosts = false;
                state.successPosts = true;
                state.errorPosts = null;
                state.posts = action.payload;
            })
            .addCase(getPostsIntitution.rejected, (state, action) => {
                state.loadingPosts = false;
                state.errorPosts = action.payload;
                state.posts = [];
            })
    }
});

export const { reset } = postsSlice.actions;
export default postsSlice.reducer;
