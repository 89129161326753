import { useEffect, useState } from "react";

//components
import { SearchInput } from "../../components/SearchInput";
import { Header, Table, Paginator, } from '../../components/ContentManager'
import { Loading } from '../../components/Loading'

//api
import SuggestionService from '../../services/SuggestionService'

//styles
import { Content, PageContainer } from "./styles";

export function ContentManager() {
    const [suggestions, setSuggestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const pagination = 5;


    const fetchSuggestions = async () => {
        try {
            const response = await SuggestionService.GetSuggestion();
            const data = response.suggestions;
            setTotalPages(Math.ceil(data.length / pagination));

            setSuggestions(data);
            setIsLoading(false);
        } catch (error) {
            setErrorMsg(error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSuggestions();
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleApprove = (id) => {
        setSuggestions(suggestions.filter(suggestion => suggestion.id !== id));
        SuggestionService.ApproveSuggestion(id);
    };

    const handleReprove = (id) => {
        setSuggestions(suggestions.filter(suggestion => suggestion.id !== id));
        SuggestionService.ReproveSuggestion(id);
    };
    
    return (
        <PageContainer>
            <Content>
                <Header Description={'Lista de Sugestões'} />
                <SearchInput />
                {isLoading ? (
                    <Loading />
                ) :
                    <Table 
                        suggestions={suggestions} 
                        onApprove={handleApprove} 
                        onReprove={handleReprove} 
                    />
                }

                <div className="paginationContent">
                    <Paginator totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                </div>
            </Content>
        </PageContainer>
    );
}
